jQuery(function () {
    const ares_min = {
        name: 5,
        ico: 8,
    };
    const ares_timeout = 450;

    var timeoutHandle = window.setTimeout(handleAres, 1000);
    var prefix = "";
    var postfix = "";
    window.clearTimeout(timeoutHandle);

    //$('body').on('input', 'input[name^="firma_nazev"], input[name^="firma_ico"], input[name^="firma"], input[name^="ico"]', handlePreAres);
    $("body").on("input", "input[data-ares-naseptavac]", handlePreAres);

    function handlePreAres(e) {
        let el = $(e.target);
        let naseptavac = $("#ares-naseptavac");
        let name = el.attr("name");
        let type_text = name == "company_name" ? "name" : "ico";
        prefix = "firma_";
        postfix = "_firma";
        let minimum = ares_min[type_text];
        naseptavac.hide();

        if (minimum <= el.val().length) {
            let url = `?do=aresSearch&${type_text}=${el.val()}`;
            let encoded = encodeURI(url);

            window.clearTimeout(timeoutHandle);
            timeoutHandle = window.setTimeout(handleAres, ares_timeout, encoded);
        } else {
            window.clearTimeout(timeoutHandle);
        }
    }

    function handleAres(url) {
        let naseptavac = $("#ares-naseptavac");
        let naseptavacIn = $("#naseptavac-items");
        naseptavacIn.empty();
        let place = $("<div/>").addClass("naseptavac-item");
        let placeholder1 = $("<p/>").addClass("naseptavac-placeholder col-5");
        let placeholder2 = $("<p/>").addClass("naseptavac-placeholder col-9");
        place.append(placeholder1);
        place.append(placeholder2);
        naseptavacIn.append(place);
        naseptavac.show();

        $.ajax({
            url: url,
            success: function (data) {
                data = JSON.parse(data);
                if (data.success) {
                    data = data.data;
                    naseptavacIn.empty();

                    if (data.length > 0) {
                        $.each(data, function (index, val) {
                            var div = $("<div/>")
                                .addClass("naseptavac-item")
                                .attr("data-ares-name", val.name)
                                .attr("data-ares-ico", val.ico)
                                .attr("data-ares-dic", val.dic)
                                .attr("data-ares-address", val.address)
                                .attr("data-ares-obec", val.city)
                                .attr("data-ares-psc", val.psc);
                            var p1 = $("<p/>")
                                .addClass("headline")
                                .html(val.name + ", IČ: " + val.ico);
                            if (val.dic) {
                                p1.append(", DIČ: " + val.dic);
                            }
                            var p2 = $("<p/>").html(val.address + ", " + val.city + ", " + val.psc);

                            div.append(p1);
                            div.append(p2);

                            naseptavacIn.append(div);
                        });

                        naseptavac.show();
                    } else {
                        naseptavacIn.empty();
                        let div = $("<div/>").addClass("naseptavac-item");
                        let p1 = $("<p/>").addClass("headline").html("Žádné výsledky");
                        let p2 = $("<p/>").html("Pro Vámi zadané údaje nebyly v systému ARES nalezeny žádné shody.");
                        div.append(p1);
                        div.append(p2);
                        naseptavacIn.append(div);

                        naseptavac.show();
                    }
                } else {
                    naseptavacIn.empty();
                    let div = $("<div/>").addClass("naseptavac-item");
                    let p1 = $("<p/>").addClass("headline").html("Problém se serverem");
                    let p2 = $("<p/>").html("Během vyhledávání v systému ARES vznikla chyba. Opakujte prosím později.");
                    div.append(p1);
                    div.append(p2);
                    naseptavacIn.append(div);

                    naseptavac.show();
                }
            },
        });
    }

    $("body").on("click", "div.naseptavac-item", function () {
        let naseptavac = $("#ares-naseptavac");
        let name = $(this).attr("data-ares-name");
        let ico = $(this).attr("data-ares-ico");
        let firmaSelector = prefix === "" ? "firma" : "firma_nazev";

        if (name) {
            /*$('input[name="' + firmaSelector + '"]').val(name);
            $('input[name="' + prefix + 'ico"]').val(ico);*/
            $('input[name="company_name"]').val(name);
            $('input[name="company_ico"]').val(ico);

            /*$('input[name="ulice' + postfix + '"]').val($(this).attr("data-ares-address"));
            $('input[name="obec' + postfix + '"]').val($(this).attr("data-ares-obec"));
            $('input[name="psc' + postfix + '"]').val($(this).attr("data-ares-psc"));*/

            /*if (ico) {
                let dicInpt = $('input[name="' + prefix + 'dic"]');
                dicInpt.val("").attr("placeholder", "Načítám DIČ z ARES..");
                let url = `?do=aresDic&ico=${ico}`;
                $.ajax({
                    url: url,
                    success: function (data) {
                        data = JSON.parse(data);

                        if (data.success) {
                            if (data.dic) {
                                dicInpt.val(data.dic);
                            }
                        }

                        dicInpt.attr("placeholder", "Zadejte DIČ");
                        //Nette.validateForm(form);
                    },
                });
            }*/
        }

        naseptavac.hide();
    });

    $("body").on("click", "#ares-naseptavac-close", function () {
        let naseptavac = $("#ares-naseptavac");
        naseptavac.hide();
    });
});
